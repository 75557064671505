import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import pageLoaderImage from "../../assets/images/bouncing-circles.svg";
import { getAnalysisCountService, getDashboardCountForUserService } from "../../services/userService";
import { useState, useEffect } from "react";


const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        position: "bottom",
        text: "Year - 2022",
      },
      legend: {
        display: false,
      },
    },
  };

const UserAnalytics = () => {
    const [chartData, setChartData] = useState();
    const [data, setData] = useState();
    const [list, setList] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);


    const analysisCount = () => {
        getAnalysisCountService().then((res) => {
            if(res.data.statusCode){
                const { user, chef, product, recipe, SO } = res.data.responseData.result;
                const _data =  {user, chef, product, recipe, SO}
                setData(res.data.responseData.result);
                let label = Object.keys(_data);
                label.pop();
                let value = Object.values(_data);
                const labels = ["BAKERS", "CHEFS", "PRODUCTS", "RECIPES", "SO"];
                
                let data = {
                    labels: labels,
                    datasets: [
                      {
                        data: value,
                        backgroundColor: [
                          "#FFBB00",
                          "#7F00A6",
                          "#00A606",
                          "#EF5D5D",
                          "#61AFDC",
                          "#9FC545",
                        ],
                        borderRadius: 4,
                      },
                    ],
                  };
                  setChartData(data);
                  setPageLoader(false)
            }
        });
      };


      const getList = () => {
        getDashboardCountForUserService().then((res) => {
            setList(res.data.responseData.result.login);
            setPageLoader(false)
        });
      };

    
      useEffect(() => {
        analysisCount();
        getList();
      }, []);

    
      if(pageLoader){
        return(
            <div className="page_loader"><img src={pageLoaderImage} /></div>
        )
    }else{

    return(
        <div className="dash_section mt-5">
            <div className="row">
                <div className="col-lg-8">
                    <div className="chart_container">
                        <h5 className=" ">All User Types</h5>
                        <hr></hr>
                        <div className="bar">
                            {chartData ? <Bar data={chartData} options={options}/> : ''}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="total_card">
                        <h5>Total Bakers</h5>
                        <h2>{data?.user}</h2>
                    </div>

                    <div className="total_card">
                        <h5>Total Chef</h5>
                        <h2>{data?.chef}</h2>
                    </div>

                    {/* <div className="total_card">
                        <h5>Total Distributor</h5>
                        <h2>{data?.distributor}</h2>
                    </div> */}

                    <div className="total_card">
                        <h5>Total Product</h5>
                        <h2>{data?.product}</h2>
                    </div>

                    <div className="total_card">
                        <h5>Total Recipe</h5>
                        <h2>{data?.recipe}</h2>
                    </div>

                    <div className="total_card">
                        <h5>Total SO</h5>
                        <h2>{data?.SO}</h2>
                    </div>
                </div>
            </div>



            <div className="">
                <h5 className="mt-5">Average Customer Lifespan</h5>

                <table className="table table-responsive">
                  <thead>
                    <tr className="medium-font font-14px">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Time</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.map((item, index) => {
                        return (
                            <tr key={index} className="regular-font font-14px">
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.time}</td>
                                <td>
                                    {item.user_role == "admin" ? 'Admin' : ''}
                                    {item.user_role == "chef" ? 'Baker' : ''}
                                    {item.user_role == "pillsbury_chef" ? 'Pillsbury Chef' : ''}
                                    {item.user_role == "store_manager" ? 'Store Manager' : ''}
                                    {item.user_role == "purchase_officer" ? 'Purchase Officer' : ''}
                                </td>
                              </tr>
                        );
                      })}
                    {list.length ? '' : <tr><td colSpan={4} className="no_table">No Data</td></tr>}
                  </tbody>
                </table>
              </div>

        </div>
    )
}
}

export default UserAnalytics;