import { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import { PageLoader } from "../svg";
import { getUserListService } from "../../services/userService";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";



const Users = () => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState("");

    const limit = 10;
    const [totalCount, setTotalCount] = useState(1);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [excelUrl,setExcelUrl]=useState(null);
    const [search, setSearch] = useState('');
    const [recordCount, setRecordCount] = useState(0);

    localStorage.removeItem('page');





    const handlePageClick = (event) => {
        setPage(event.selected + 1)
    };

    const userList = (downloadFlag) => {
        let query = {
            "limit": limit,
            "page": page,
            ...((search!=='' && search!==null) &&  {name: search}),

        }

        getUserListService(query).then((res) => {
            setLoader(false)
            setList(res.data.responseData.data)
            let count = res.data.responseData.count;
            setRecordCount(count);

            let numberOfPages = Math.ceil(count / 10);
            setTotalCount(numberOfPages);
        })
    }
    const download = () => {
        let query = {
            "download": 1
        }

        getUserListService(query).then((res) => {
            if(res.data.responseData && res.data.responseData.file)
                setExcelUrl(res.data.responseData.file)
        })
    }
    useEffect(() => {
        userList()
    }, [page,search])

   


    useEffect(()=>{
        download()
    },[])

    const searchChange = (e) =>{
        setSearch(e.target.value)
        setPage(0)
    }

  


    return (
        <>
            <div className="page_wrapper">
                    <div className="container-box">



                        <div className=" distributor-head">
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h4 className="semibold-font">BAKER LIST</h4>
                                </div>
                                <div className="col-md-3 ">
                                    <img src={SearchIcon} className="search-icon" />



                                    <input type="text" placeholder="Search by name" name="search" className=" search box-shadow light-italic-font" 
                                    value={search} onChange={searchChange}
                                    >

                                    </input>

                                </div>

                                <div className="col-md-2 ">
                                    <div>
                                        <a className="add-btn text-white box-shadow  medium-font grey-font" href={excelUrl} download>
                                            Download
                                        </a>
                                    </div>

                                   

                                </div>









                            </div>
                        </div>

                        <div className="table-content">
                            <Table className=" box-shadow common-black-color">
                                <thead>
                                    <tr className="medium-font font-14px">
                                        <th >ID</th>
                                        <th >Image</th>



                                        <th >Name</th>
                                        <th>Phone Number</th>
                                        <th>Email Address</th>

                                        <th>City</th>
                                        <th>Bakery</th>
                                        <th>Distributor</th>
                                        <th>Asm</th>






                                    </tr>
                                </thead>

                                <tbody>
                                    {list && list.map((item, index) => (
                                        <tr className="regular-font font-14px">
                                            <td>{(limit * pageCount) + index + 1}</td>
                                            <td><img src={item.profile_image} width={50} height={40} alt="image" /></td>
                                            <td className="">{item.name}</td>
                                            <td className="">{item.phone_number}</td>
                                            <td className="">{item.email}</td>
                                            <td className="">{item.homeCity}</td>
                                            <td className="">{item.bakeryName}</td>
                                            <td className="">{item.distributorName}</td>
                                            <td className="">{item.asmTerritory}</td>

                                            

                                            



                                          

                                        </tr>

                                    ))}
                                       {list?.length ? "" :
                                            <tr className="text-center">
                                                <td></td>

                                                <td className="text-end no_data">

                                                    <p>No Data Found</p>
                                                </td>

                                            </tr>
                                        }

                                </tbody>
                            </Table>
                            {loader ? <div className='loader_data'><PageLoader /></div> : ""}
                           {recordCount>limit? <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={totalCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination "}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                hrefBuilder={(page, pageCount, selected) =>
                                    setPageCount(selected)
                                }
                            />:null
                            }
                        </div>
                    </div>
            </div>

        </>
    )
}
export default Users;