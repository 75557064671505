import settings from "./settings";
import axios from 'axios'
axios.defaults.headers.common['authorization'] = process.env.REACT_APP_BASIC_AUTH;

export  const API = {

  // Login api
  "ADMIN_LOGIN": settings.api.url + "user/login/",
  "GET_USERS": settings.api.url + "user/getUserListByRole",

  // Used Bakery api
  "GET_USED_BAKERY": settings.api.url + "user/getUsedBakeryList",


  // Distributor api's
  "GET_DISTRIBUTOR": settings.api.url + "distributor",
  "ADD_DISTRIBUTOR": settings.api.url + "distributor/addDistributor",
  "DELETE_DISTRIBUTOR": settings.api.url + "distributor/deleteDistributor",
  "UPDATE_DISTRIBUTOR": settings.api.url + "/distributor/updateDistributor",


  // Baker api's
  "GET_BAKER": settings.api.url + "bakery",
  "ADD_BAKER": settings.api.url + "bakery/addBakery",
  "DELETE_BAKER": settings.api.url + "bakery/deleteBakery",
  "UPDATE_BAKER": settings.api.url + "bakery/updateBakery",



  // Feedback api
  "GET_FEEDBACK": settings.api.url + "feedback/getFeedback",

  // Product api's
  "GET_PRODUCT": settings.api.url + "product/getProduct",
  "ADD_PRODUCT": settings.api.url + "product/createProduct",
  "UPDATE_PRODUCT": settings.api.url + "product/updateProduct",
  "DELETE_PRODUCT": settings.api.url + "product/deleteProduct",

  // Recipe api's
  "GET_RECIPE": settings.api.url + "recipe/getRecipe",
  "ADD_RECIPE": settings.api.url + "recipe/createRecipe",
  "DELETE_RECIPE": settings.api.url + "recipe/deleteRecipe",
  "UPDATE_RECIPE": settings.api.url + "recipe/updateRecipe",

  // Product category api's
  "ADD_PRODUCT_CATEGORY": settings.api.url + "product/createProductCategory",
  "UPDATE_PRODUCT_CATEGORY": settings.api.url + "product/updateProductCategory",
  "TRANSLATE_CONTENT": settings.api.url + "product/translateContent",
  "GET_PRODUCT_BY_ID": settings.api.url + "product/getProductById",

  "UPLOAD_FILE": settings.api.url + "product/uploadFile",
  "CAKE_DESIGN_UPLOAD_FILE": settings.api.url + "cake-design/uploadFile",
  "CAKE_DESIGN_DELETE_FILE": settings.api.url + "cake-design/deleteCakeDesignUrl",
  "GET_PRODUCT_CATEGORY": settings.api.url + "product/getAllProductCategory",
  "GET_PRODUCT_CATEGORY_DETAILS": settings.api.url + "product/getProductCategoryDetails",
  "DELETE_PRODUCT_CATEGORY": settings.api.url + "product/deleteProductCategory",

  // Pillsbury chef api's
  "GET_PILLSBURY_CHEF": settings.api.url + "user/getAllPillsUser",
  "ADD_PILLSBURY_CHEF": settings.api.url + "user/addUser",
  "UPDATE_PILLSBURY_CHEF": settings.api.url + "user/updateProfile",
  "DELETE_PILLSBURY_CHEF": settings.api.url + "user/deleteUser",



  // Notification api's
  "SEND_NOTIFICATION": settings.api.url + "notification/createNotification",
  "GET_NOTIFICATION": settings.api.url + "notification/getNotification",
  "DELETE_NOTIFICATION": settings.api.url + "notification/deleteNotification",

  // City api
  // "GET_CITY": settings.api.url + "masterData/getCity",

  // Dashboard api's
  "GET_ANALYSIS_COUNT": settings.api.url + "admin/dashboardAnalysisCount",
  "GET_DASHBOARD_COUNT": settings.api.url + "admin/dashboardCount",
  "GET_DASHBOARD_COUNT_FOR_USER": settings.api.url + "admin/dashboardCountForUser",
  "GET_DASHBOARD_RECIPE_PLAY_COUNT": settings.api.url + "admin/dashboardRecipeVideoPlayCount",
  "GET_RECIPE_PLAY_TIME": settings.api.url + "admin/getRecipeVideoPlayTime",
  "GET_RECIPE_TEXT_VIEW_TIME": settings.api.url + "admin/getRecipeTextViewTime",
  "GET_RECIPE_TEXT_VIEW_COUNT": settings.api.url + "admin/getRecipeTextViewCount",
  "GET_RECIPE_TEXT_VIDEO_SKIP_TIME": settings.api.url + "admin/getRecipeVideoSkipTime",
  "GET_PRODUCT_TEXT_VIEW_TIME": settings.api.url + "admin/getProductTextViewTime",
  "GET_PRODUCT_TEXT_VIEW_COUNT": settings.api.url + "admin/getProductTextViewCount",
  "GET_DASHBOARD_SLOT_COUNT": settings.api.url + "admin/dashboardSlotCount",
  "GET_RECIPE_DASHBOARD_DATA": settings.api.url + "admin/getRecipeDashBoardData",
  "GET_PRODUCT_DASHBOARD_DATA": settings.api.url + "admin/getProductDashBoardData",







  // ASM api
  "GET_ASM": settings.api.url + "masterData/getASM",
  "UPDATE_ASM": settings.api.url + "masterData/updateASM",
  "CREATE_ASM": settings.api.url + "masterData/createASM",
  "DELETE_ASM": settings.api.url + "masterData/deleteASM",

  // City Api
  "GET_CITY": settings.api.url + "masterData/getCity",
  "CREATE_CITY": settings.api.url + "masterData/createCity",
  "UPDATE_CITY": settings.api.url + "masterData/updateCity",
  "DELETE_CITY": settings.api.url + "masterData/deleteCity",




  // SO api
  "GET_SO": settings.api.url + "masterData/getSO",
  "GET_SO_LIST": settings.api.url + "user/getAllSOUser",
  "UPDATE_SO": settings.api.url + "masterData/updateSO",
  "CREATE_SO": settings.api.url + "masterData/createSO",
  "DELETE_SO": settings.api.url + "masterData/deleteSO",

  // Faq api

  "GET_FAQ": settings.api.url + "masterData/getFAQ",
  "ADD_FAQ": settings.api.url + "masterData/createFAQ",
  "DELETE_FAQ": settings.api.url + "masterData/deleteFAQ",
  "UPDATE_FAQ": settings.api.url + "masterData/updateFAQ",
  "GET_DESIGN_CATEGORY": settings.api.url + "cake-design/getAllCategory",
  "GET_DESIGN_CATEGORY_LIST": settings.api.url + "cake-design/getPaginatedCategory",
  "GET_DESIGN_SUB_CATEGORY": settings.api.url + "cake-design/getAllSubCatByCategory",
  "CREATE_DESIGN_CATEGORY": settings.api.url + "cake-design/addDesignCategory",
  "CREATE_DESIGN_SUB_CATEGORY": settings.api.url + "cake-design/addDesignSubCategory",
  "DELETE_DESIGN_CATEGORY": settings.api.url + "cake-design/deleteCategory",
  "DESIGN_CATEGORY_DETAIL": settings.api.url + "cake-design/getCategoryById",
  "UPDATE_DESIGN_CATEGORY": settings.api.url + "cake-design/updateCategory",
  "UPDATE_STATUS_DESIGN_CATEGORY": settings.api.url + "cake-design/activeInactiveCategory",
  "DELETE_DESIGN_SUB_CATEGORY": settings.api.url + "cake-design/deleteSubCategoryById",
  "GET_CAKE_DESIGNS": settings.api.url + "cake-design/getCakeDesigns",
  "CREATE_CAKE_DESIGN": settings.api.url + "cake-design/createCakeDesign",
  "GET_CAKE_DESING_DETAIL_BY_ID": settings.api.url + "cake-design/getCakeDesignById",
  "UPDATE_CAKE_DESIGN": settings.api.url + "cake-design/updateCakeDesign",
  "DELETE_CAKE_DESIGN": settings.api.url + "cake-design/delete",


  "GET_MASTER_DATA": settings.api.url + "masterData/getAnalytics",
  "GET_APPOINTMENT_DATA": settings.api.url + "admin/dashboardSlotCountYearMonthly",
  "GET_DROP_DWONS_CAT": settings.api.url + "cake-design/getAllCategory",
  "GET_PRODUCT_USED_LIST": settings.api.url + "cake-design/getDesignCategoryByType",
  "GET_CHURN_RATE": settings.api.url + "masterData/getChurnRate",
  "GET_RETENTION_RATE": settings.api.url + "masterData/getRetentionRate",
  "GET_DASHBOARD_SLOT_COUNT_MONTHLY": settings.api.url + "admin/dashboardSlotCountMonthly",
  "GET_TOOLKIT_DESIGN": settings.api.url + "toolkit-design/getToolkitDesigns",
  "GET_TOOLKIT_DESIGN_CATEGORY": settings.api.url + "toolkit-design/getPaginatedCategory",
  "CREATE_TOOLKIT_DESIGN_CATEGORY": settings.api.url + "toolkit-design/addToolkitCategory",
  "DELETE_TOOLKIT_DESIGN_CATEGORY": settings.api.url + "toolkit-design/deleteCategory",
  "UPDATE_TOOLKIT_DESIGN_CATEGORY": settings.api.url + "toolkit-design/updateCategory",
  "TOOLKIT_DESIGN_CATEGORY_DETAIL": settings.api.url + "toolkit-design/getCategoryById",
  "UPDATE_STATUS_TOOLKIT_DESIGN_CATEGORY": settings.api.url + "toolkit-design/activeInactiveCategory",


  "CREATE_TOOLKIT_DESIGN": settings.api.url + "toolkit-design/createToolkitDesign",
  "UPDATE_TOOLKIT_DESIGN": settings.api.url + "toolkit-design/updateToolkitDesign",
  "GET_TOOLKIT_DESIGN_DETAIL": settings.api.url + "toolkit-design/getToolkitDesignById",
  "DELETE_TOOLKIT_DESIGN": settings.api.url + "toolkit-design/delete",
  "UPLOAD_TOOLKIT_FILE": settings.api.url + "toolkit-design/uploadFile",
  "GET_DROP_DWONS_CAT_FOR_TOOLKIT": settings.api.url + "toolkit-design/getAllCategory",
  "TOOLKIT_DESIGN_FAVORITE": settings.api.url + "toolkit-design/favorite",
  "GET_ALL_SO_REPORTS": settings.api.url + "so-report/getAllSoReports",
  "UPLOAD_SO_REPORT": settings.api.url + "so-report/uploadSheet",


}
