import { useEffect, useState } from "react";
import { getAllSoReportService, uploadSoReportService } from "../../services/userService";
import { Spinner, Table, Toast } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { PageLoader } from "../svg";
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';
import demoTemplate from '../../assets/so-report-template.xlsx';


const titles = [
    'SO Name',
    'Conversion',
    'Reason',
    'Comment',
    'ASM Name',
    'Bakery Code',
    'Bakery Name',
    'Category',
    'City',
    'Customer Classfication',
    'Customer Status',
    'DB Code',
    'DB Name',
    'Last Month Tonnage',
    'Region',
    'SKU Name',
    'SO Email',
    'Created At',
    'Updated At',
];

const SoReport = () => {

    const [pageLoader, setPageLoader] = useState(false);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [fileUploadLoader, setFileUploadLoader] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [tempSoReport, setTempSoReport] = useState([]);

    const limit = 10;




    const getAllSoReport = async () => {
        const query = `?page=${page}&limit=${limit}`;
        setPageLoader(true);
        try {
            const res = await getAllSoReportService(query);
            if(res.data.statusCode){
                setList(res.data.responseData.result)
                setTotalCount(res.data.responseData.total);
                setPageLoader(false);


                if(res.data.responseData.total){
                    const queryIn = `?page=1&limit=${res.data.responseData.total}`;
                    const resIn = await getAllSoReportService(queryIn);
                    if(resIn.data.statusCode){
                        let _tempSoReport = resIn.data.responseData.result.map(item => [
                            item.userName,
                            item.conversion,
                            item.reason,
                            item.comment,
                            item.asm_name,
                            item.asm_name,
                            item.bakeryName,
                            item.categoryName,
                            item.city,
                            item.customerClassfication,
                            item.customerStatus,
                            item.dbCode,
                            item.dbName,
                            item.lastMonthTonnage,
                            item.region,
                            item.skuName,
                            item.so_email,
                            item.createdAt,
                            item.updatedAt,
                        ]);
                        _tempSoReport.unshift(titles);
                        setTempSoReport(_tempSoReport)
                    }
                }


                



            }
        } catch (error) {
            setPageLoader(false);
            console.error('Error fetching SO report:', error);
        }
    };



    const downloadReport = () => {
        const ws = XLSX.utils.aoa_to_sheet(tempSoReport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = String(now.getFullYear()).slice(-2);
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');


        XLSX.writeFile(wb, `so_report-${day}-${month}-${year}-${hours}-${minutes}-${seconds}.xlsx`);
    };

    useEffect(() => {
        getAllSoReport()
    }, [page])



    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setFileUploadLoader(true);
    
        const formData = new FormData();
        formData.append("file", file);
    
        try {
            const response = await uploadSoReportService(formData);
            if(response.data.statusCode){
                setSuccessMsg(response.data.responseData.message)
                setPage(1);
                getAllSoReport();
            }

        } catch (error) {

        } finally {
            setFileUploadLoader(false);
        }
    }
    


    useEffect(() => {
        if(successMsg){
            setTimeout(() => {
                setSuccessMsg('')
            }, 3000)
        }
    }, [successMsg])
    


    
    return (
        <div className="page_wrapper">
            {successMsg ? <div className="cm_toast">{successMsg}</div> : ''}
            



            <div className="container-box">

                <div className="search_part d-flex align-items-center justify-content-between w-100 mt-3 mb-2">
                    <h4 className="semibold-font mb-0 ms-2">SO Report <a href={demoTemplate} download={demoTemplate} className="btn btn-success btn-sm me-3">Download Template</a></h4>
                    <div className="btn_part">
                    <span className="btn btn-primary btn-sm me-3" onClick={downloadReport}>Download SO Report</span>
                        <span className="add-btn text-white box-shadow medium-font grey-font text-decoration-none cm_upload_report_btn">
                            Upload Report {fileUploadLoader ? <Spinner className="spiner" animation="border" /> : <input onChange={handleFileChange} type="file" accept=".xls,.xlsx" />}
                            
                        </span>
                    </div>
                </div>


                <div className="table-responsive">
                    <Table style={{width: '5000px'}} className="table box-shadow">
                        <thead>
                            <tr>
                                <th>S. No</th>
                                <th>SO Name</th>
                                <th>Conversion</th>
                                <th>Reason</th>
                                <th>Comment</th>
                                <th>ASM Name</th>
                                <th>Bakery Code</th>
                                <th>Bakery Name</th>
                                <th>Category</th>
                                <th>City</th>
                                <th>Customer Classfication</th>
                                <th>Customer Status</th>
                                <th>DB Code</th>
                                <th>DB Name</th>
                                <th>Last Month Tonnage</th>
                                <th>Region</th>
                                <th>SKU Name</th>
                                <th>SO Email</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            {list?.length ? list.map((item, index) => (
                                <tr>
                                    <td><span>{((index + 1) + ((page - 1) * limit))}</span></td>
                                    <td>{item.userName}</td>
                                    <td>{item.conversion ? 'Yes' : 'No'}</td>
                                    <td>{item.reason}</td>
                                    <td>{item.comment}</td>
                                    <td>{item.asm_name}</td>
                                    <td>{item.bakeryCode}</td>
                                    <td>{item.bakeryName}</td>
                                    <td>{item.categoryName}</td>
                                    <td>{item.city}</td>
                              
                                    <td>{item.customerClassfication}</td>
                                    <td>{item.customerStatus}</td>
                                    <td>{item.dbCode}</td>
                                    <td>{item.dbName}</td>
                                    <td>{item.lastMonthTonnage}</td>
                                    <td>{item.region}</td>
                                    <td>{item.skuName}</td>
                                    <td>{item.so_email}</td>
                                    <td>{dateFormat(item.createdAt, "dS mmmm yyyy")}</td>
                                    <td>{dateFormat(item.updatedAt, "dS mmmm yyyy")}</td>
                                </tr>
                            )) :
                                <tr className="text-center">
                                    <td></td>
                                    <td className="text-end no_data">
                                        <p>No Data Found</p>
                                    </td>
                                </tr>
                            }
                        </tbody>

                    </Table>
                </div>
                        {pageLoader ? <div className='loader_data'><PageLoader /></div> : ""}

                        {list.length ? <div className="cm_pagination mt-4"><Pagination
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={p => setPage(p)} /></div> : ''}
                    
                    </div>
            </div>




    )
}

export default SoReport;