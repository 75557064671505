import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../assets/images/edit (5).svg";
import DeleteIcon from "../../assets/images/trash (1).svg";
import ModalLogo from "../../assets/images/officer (1).svg";
import {getCityService, updateSOService, deleteSOService, createSOService, getSoListService} from "../../services/userService";
import { Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/cross_icon.svg";
import { PageLoader } from "../svg";
import DeleteModal from "./../../Hooks/DeleteModal";
import Dropdown from "./../../Hooks/Dropdown";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/images/search (5).svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";


const SO = () => {
    const [list, setList] = useState("");
    const [loader, setLoader] = useState(true);


    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);


    const [city, setCity] = useState('');
    const [cityErr, setCityErr] = useState('');

    const [id, setId] = useState('');
    const [cityList, setCityList] = useState([]);


    const [soName, setSOName] = useState('');
    const [soNameErr, setSONameErr] = useState('');

    const [updateSO, setUpdateSO] = useState("");
    const [updateSOErr, setUpdateSOErr] = useState("");


    const [search, setSearch] = useState('');
    const limit = 10;
    const [totalCount, setTotalCount] = useState(1);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);


    const [sortKey, setSortKey] = useState('');
    const [type, setType] = useState('');
    const [recordCount, setRecordCount] = useState(0);
    localStorage.removeItem('page');





    const arrowClick = (sortType, sortOrder) => {
        setSortKey(sortType)
        setType(sortOrder)
    }

    const handlePageClick = (event) => {
        let pageNumber = event.selected + 1;

        setPage(event.selected + 1)
        if (search !== "" && search !== null) {
            getSOList({
                limit: limit,
                page: pageNumber,
                search: search,
            });
        } else {
            getSOList({
                limit: limit,
                page: pageNumber,
            });
        }
    };

    const handleAddSO = () => {
        setCreateModal(true)
    }

    const getSOList = () => {
        let query = `?limit=${limit}&page=${page}`
        getSoListService(query).then((res) => {
            setList(res.data.responseData.data)
            setTotalCount(res.data.responseData.count);
            setLoader(false);
        })
    }

    const getCityList = () => {
        getCityService().then((res) => {
            setCityList(res.data.responseData.result);
        })
    }

    const updateSOFunctionData = (event, key) => {
        setUpdateSO((prev) => ({ ...prev, [key]: event }))
    }

    useEffect(() => {
        getSOList();
        getCityList();
    }, [page, type, sortKey])
    useEffect(() => {
        let _intervalTime;
        if (search !== "" && search !== null) {
            _intervalTime = setTimeout(() => {
                getSOList();
            }, 1500);
        } else {
            getSOList();
        }
        return () => {
            clearTimeout(_intervalTime);
        };
        // eslint-disable-next-line
    }, [search]);


    const deleteSO = (_id) => {
        setDeleteModal(true)
        setId(_id)
    }


    const updateSOClick = (item) => {
        setUpdateModal(true)
        setUpdateSO(item)
        setCity(item.so_city_code)
    }

    const handleClose = () => {
        setDeleteModal(false)
        setCreateModal(false)
        setSOName('')
        setSONameErr('')
        setCity('')
        setCityErr('')
        setUpdateSO('')
    }

    const handleUpdateCloseFunction = (e) => {
        setUpdateModal(false)
    }
    // To prevent entering white spaces in add


    const handleKeyDown = (e) => {
        if (e.key === " " && soName.length === 0) {
            e.preventDefault();
        }
    };

    // Add validation

    const validation = () => {
        let validate = true;
        if (soName == "") {
            setSONameErr("SO name is required")
            validate = false;
        }
        else if (soName.length < 2) {
            setSONameErr("SO name should be between 2 to 30")
            validate = false;
        }
        if (city === "") {
            setCityErr("City is required")
            validate = false;
        }

        return validate;
    }

    // To prevent entering white spaces in update


    const handleUpdateKeyDown = (e) => {
        if (e.key === " " && updateSO.so_name.length === 0) {
            e.preventDefault();
        }
    };


    const updateValidation = () => {
        let validate = true;
        if (updateSO.so_name == "") {
            setUpdateSOErr("SO name is required")
            validate = false;
        }
        else if (updateSO.so_name.length < 2) {
            setUpdateSOErr("SO name should be between 2 to 30")
            validate = false;
        }


        return validate;
    }

    //create ASM
    const handleAddClick = () => {

        if (validation()) {
            let params = {
                so_name: soName,
                so_city_code: city,
                user_role: "SO"
            }
            createSOService(params).then((res) => {
                setCreateModal(false)
                getSOList()
            })

        }

    }

    //delete ASM

    const handleDeleteClick = () => {
        deleteSOService({ _id: id }).then((res) => {
            setDeleteModal(false)
            getSOList()
        })
    }


    //update ASM

    const handleUpdateClick = () => {
        if (updateValidation()) {
            if (updateSO.so_name && updateSO.so_city_code) {
                updateSOService({ so_name: updateSO.so_name, so_city_code: city, _id: updateSO._id }).then(res => {
                    setUpdateModal(false)
                    getSOList();
                })
            }

        }




    }

    return (
        <>

            <div className="page_wrapper">
                    <div className="container-box">

                        <div className=" distributor-head">
              <div className="row mt-4">
                <div className="col-md-4">
                  <h4 className="semibold-font">SALES OFFICER (SO)</h4>
                </div>

                <div className="col-md-5 ">
                  <img src={SearchIcon} className="search-icon" />

                  <input
                    type="text"
                    placeholder="Search by name"
                    name="search"
                    className=" search box-shadow light-italic-font"
                    value={search}
                    onChange={(e) => (setSearch(e.target.value), setPage(0))}
                  ></input>
                </div>

                <div className="col-md-2  ">
                  <button
                    className="add-btn text-white box-shadow  medium-font grey-font"
                    onClick={handleAddSO}
                  >
                    Add SO
                  </button>
                </div>
              </div>
            </div>
                        <Table className="table box-shadow">
                            <thead>
                                <tr>
                                    <th>S. No</th>
                                    <th>SO Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>City</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list && list.map((item, index) => (
                                    <tr>
                                        <td><span>{((index + 1) + ((page - 1) * limit))}</span></td>
                                        <td><img className="table_user_img" src={item.profile_image} /> {item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone_number}</td>
                                        <td>{item.home_city}</td>
                                        <td>
                                            <img src={EditIcon} alt="edit" onClick={() => updateSOClick(item)} />
                                            <img src={DeleteIcon} alt="delete" className="dlt-icon" onClick={() => deleteSO(item._id)} />
                                        </td>
                                    </tr>
                                ))}
                                {list?.length ? "" :
                                    <tr className="text-center">
                                        <td></td>
                                        <td className="text-end no_data">
                                            <p>No Data Found</p>
                                        </td>
                                    </tr>
                                }
                            </tbody>

                        </Table>
                        {loader ? <div className='loader_data'><PageLoader /></div> : ""}

                        {recordCount > limit ? <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={totalCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination "}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            hrefBuilder={(page, pageCount, selected) =>
                                setPageCount(selected)
                            }
                        /> : null}
                    </div>
            </div>

            {/* Delete Product Category Modal */}

            <DeleteModal
                deleteModal={deleteModal}
                title="SO"
                Icon={CrossIcon}
                handleClose={handleClose}
                handleDeleteClick={handleDeleteClick}
            ></DeleteModal>

            {/* Create Product Category Modal */}

            <Modal show={createModal} onHide={handleClose} centered >
                <Modal.Header closeButton className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={ModalLogo} />
                            <h5 className="semibold-font mt-3">Add Sales Officer (SO)</h5>
                        </div>
                        <input type="text" placeholder="SO NAME" className="form-control mt-4 smaller-font" value={soName} maxLength={50} onKeyDown={handleKeyDown}
                            onChange={(e) => (setSOName(e.target.value), setSONameErr(''))} />
                        <div className="error-message">{soNameErr ? soNameErr : ""}</div>


                        <Dropdown
                            list={cityList}
                            title="CITY"
                            value={city}
                            showValue="city_name"
                            bindValue="city_name"
                            setSelectedValue={setCity}
                            setError={setCityErr}

                        ></Dropdown>
                        <div className="error-message">{cityErr ? cityErr : ""}</div>


                        <button className="add-modal-btn mt-3  btn-padding text-white" onClick={handleAddClick}>ADD</button>
                    </div>

                </Modal.Body>

            </Modal>

            {/*Update SO modal*/}

            <Modal show={updateModal} onHide={handleUpdateCloseFunction} centered >
                <Modal.Header closeButton className="display"></Modal.Header>

                <Modal.Body>
                    <div className='modal-box'>
                        <div className="text-center">
                            <img src={ModalLogo} />
                            <h5 className="semibold-font mt-3">Update Sales Officer (SO)</h5>
                        </div>
                        <input type="text" placeholder="SO NAME" className="form-control mt-4 smaller-font" onKeyDown={handleUpdateKeyDown}
                            value={updateSO.so_name} onChange={(e) => (updateSOFunctionData(e.target.value, "so_name"), setUpdateSOErr(''))} />
                        <div className="error-message">{updateSOErr ? updateSOErr : ""}</div>

                        <Dropdown
                            list={cityList}
                            title="CITY"
                            value={city}
                            showValue="city_name"
                            bindValue="city_name"
                            setSelectedValue={setCity}
                        ></Dropdown>

                        <button className="add-modal-btn mt-3  update-btn-padding text-white" onClick={handleUpdateClick}>UPDATE</button>
                    </div>

                </Modal.Body>

            </Modal>

        </>

    )
}


export default SO;