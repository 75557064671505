import React, {useEffect, useState, useRef, useCallback} from 'react';
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';

import camera from "../../assets/images/camera.svg";
import EditIcon from "../../assets/images/edit (5).svg";

import 'react-image-crop/dist/ReactCrop.css'



const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            resolve(blob);
        }, 'image/png');
    });
}



const ImageCroper = ({cropCallback, oldImg}) => {
    const imgRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [imgBoxView, setImgBoxView] = useState('');
    const [uploadImage, setUploadImage] = useState('');
    const [imgFileView, setImgFileView] = useState('');
    const [cImage, setCImage] = useState('');
    const [crop, setCrop] = useState({ unit: 'px', x: 0, y: 0, width: 400, height: 350, aspect: 400 / 350, resize: true });


    useEffect(() => {
        if(oldImg){
            setImgBoxView(oldImg);
        }
    }, [oldImg])


    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setCImage(file);
        
        reader.addEventListener('load', () => {
            setImgFileView(reader.result);
            setShowModal(true);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    const handleClose = () => {
        setImgFileView('');
        if(oldImg){
            setImgBoxView(oldImg);
        }
        setShowModal(false);
        setCrop({ unit: 'px', x: 0, y: 0, width: 400, height: 350, aspect: 400 / 350, resize: true })
    }

    const onCropChange = (newCrop) => {
        const fixedCrop = { ...newCrop, width: crop.width, height: crop.height };
        setCrop(fixedCrop);
    };


    const completedCrop = crop => {
        getCroppedImg(imgRef.current, crop, cImage.name).then(res => (setUploadImage(res), setImgBoxView(URL.createObjectURL(res))))
    }


    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);



    const handleCropSave = () => {
        cropCallback(uploadImage);
        handleClose()

    }


    return (
      <div className="img_croper_wrapper">
        <div className="cm_file_upload">
            <input
                value=""
                onChange={handleFileChange}
                type="file" />
                {imgBoxView ? <img className="cm_uploaded_file" src={imgBoxView} /> : <img className="cm_upload_icon" src={camera} />}
                {imgBoxView ? <img className="cm_edit_icon" src={EditIcon} /> : ''}
            
        </div>

        <h6 className="text-center mt-2">Image size: 160 x 170</h6>

        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton className="display"></Modal.Header>

            <Modal.Body>
                <ReactCrop
                    src={imgFileView}
                    onImageLoaded={onLoad}
                    onChange={onCropChange}
                    onComplete={completedCrop}
                    crop={crop}>
                    <img ref={imgRef} src={imgFileView} />
                </ReactCrop>

                <div className='text-center'>
                    <span onClick={handleCropSave} className='btn btn-dark px-5'>Crop</span>
                </div>

                
            </Modal.Body>
        </Modal>
      </div>
    );
}

export default ImageCroper